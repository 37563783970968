import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PurpleAccentHero from "../components/PurpleAccentHero";
import ButtonLink from "../components/ButtonLink";
import ProductCoreValuesSection from "../components/product/ProductCoreValuesSection";
import InternalButtonLink from "../components/InternalButtonLink";
import ResponsiveVideo from "../components/ResponsiveVideo";
import { IMAGES_PATH } from "../utils/constants";
import CookiesSnackbar from "../components/CookiesSnackbar";
import { useTypography } from "../styles/Typography";
import { makeStyles } from "@material-ui/styles";
import { WORKSHOP } from "../utils/routes";
import DiscoverMoreBanner from "../components/DiscoverMoreBanner";
import { Theme } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  titleHeadingGradientWrapper: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(132.83deg, #FFFFFF 26.33%, rgba(255, 255, 255, 0) 65.07%)",
  },
  titleHeadingText: {
    marginLeft: 100,
    maxWidth: 406,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",
    fontFamily: "Ubuntu",

    color: "rgba(0, 0, 0, 0.8)",
    letterSpacing: "0.15px",

    "@media (max-width: 959px)": {
      margin: "0 auto",
      padding: "0 24px",
      marginBottom: 20,
      "& h1": {
        fontSize: 20,
        lineHeight: "24px",
        fontWeight: 400,
      },
      "& a": {
        margin: "0 auto",
      },
    },
  },
  subHeading: {
    fontSize: 20,
    lineHeight: "32px",
    "@media (max-width: 959px)": {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: "0.15px",
    },
  },
  titleDescription: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    "@media (max-width: 959px)": {
      fontFamily: "Open Sans",
      fontWeight: 400,
      lineHeight: "21px",
      fontSize: 14,
      letterSpacing: "0.25px",
    },
  },
  videoSection: {
    textAlign: "center",
    maxWidth: 1100,
    margin: "0 auto",
    marginBottom: 60,
    "& h2": {
      marginBottom: 24,
    },
    "& video": {
      boxShadow:
        "0 4px 2px -2px rgba(0, 0, 0, 0.08), 0px 12.5px 128px rgba(0, 0, 0, 0.08)",
      borderRadius: 4,
    },
  },
  unlockingSection: {
    margin: "0 auto",
    textAlign: "center",
    marginTop: 16,
    maxWidth: 1067,
    "@media (max-width: 959px)": {
      marginTop: 32,
      "& h2": {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: "2px",
        textTransform: "uppercase",
        padding: "0 32px",
      },
    },
    "& ul": {
      listStyleType: "none",
      margin: "30px 0 0 0",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      fontSize: 20,
      lineHeight: "24px",
      color: "rgba(0, 0, 0, 0.6)",
      fontFamily: "Ubuntu",
      "@media (max-width: 959px)": {
        justifyContent: "center",
        padding: "0 32px",
        fontSize: 16,
        fontWeight: 500,
      },
    },
    "& li": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: 304,
      "& div": {
        height: 200,
        marginBottom: 30,
        padding: "0 40px",
        "@media (max-width: 959px)": {
          height: 175,
          marginBottom: 15,
        },
      },
      "@media (max-width: 959px)": {
        "& p": {
          marginTop: 20,
          marginBottom: 40,
        },
      },
    },
  },
  createWorldWrapper: {
    margin: "0 auto",
    maxWidth: 1135,
    minWidth: 1050,
    "@media (max-width: 959px)": {
      minWidth: "100%",
      maxWidth: "100%",
    },
  },
  createWorldSection: {
    color: "white",
    background: "#654EA3",
    borderRadius: 4,
    display: "flex",
    marginRight: 55,
    marginTop: 60,
    marginBottom: 100,
    padding: "60px 40px 37px 40px",
    height: 700,
    "@media (max-width: 959px)": {
      minWidth: "100%",
      maxWidth: "100%",
      height: "100%",
      padding: 0,
      borderRadius: 0,
      flexDirection: "column",
      marginTop: 180,
      marginBottom: 160,
    },
  },
  createWorldText: {
    marginRight: 40,
    "& h2": {
      fontSize: 32,
      lineHeight: "40px",
      marginBottom: 16,
      "@media (max-width: 959px)": {
        fontSize: 23,
        lineHeight: "28px",
      },
    },
    "& p": {
      fontFamily: "Open Sans",
      fontWeight: 400,
    },
    "@media (max-width: 959px)": {
      flexBasis: "auto",
      margin: 0,
      padding: "16px 32px",
    },
  },
  createWorldImageWrapper: {
    width: "40%",
    "@media (max-width: 959px)": {
      width: "100%",
      flexBasis: "auto",
      flexShrink: 0,
      padding: "0 32px",
      "& img": {
        borderRadius: 4,
        boxShadow:
          "0px 24px 32px rgba(0, 0, 0, 0.08), 0px 12.5px 128px rgba(0, 0, 0, 0.08)",
      },
    },
  },
  createWorldImageDesktop: {
    position: "absolute",
    right: 110,
    top: -175,
  },
  createWorldImageMobile: {
    marginTop: -160,
  },
  createWorldImageTwoMobile: {
    marginBottom: -160,
  },
  tabsSection: {
    background: "white",
  },
  insightsSection: {
    marginBottom: 50,
    padding: 60,
    maxWidth: 1200,
    margin: "0 auto",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    alignItems: "center",
    "& h2": {
      textTransform: "uppercase",
      fontFamily: "Ubuntu",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "16px",
      letterSpacing: "1.25px",
      "@media (max-width: 959px)": {
        marginTop: 8,
      },
    },
    "& aside": {
      fontSize: 32,
      fontFamily: "Ubuntu",
      fontWeight: 500,
      lineHeight: "40px",
      letterSpacing: "0.25px",
      margin: "16px 0",
      "@media (max-width: 959px)": {
        fontSize: 24,
        lineHeight: "28px",
      },
    },
    "& a": {
      marginTop: 16,
    },
    "& ul": {
      padding: "0 32px",
    },
    "@media (max-width: 959px)": {
      flexDirection: "column",
      padding: "32px 0",
      "& h2, aside, p": {
        padding: "0 32px",
      },
      "& a": {
        marginLeft: 32,
        marginTop: 32,
      },
    },
  },
  insightsSectionMainBox: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    "@media (max-width: 959px)": {
      gridTemplateColumns: "auto",
    },
  },
  insightsSectionTitle: {
    "&&": {
      textTransform: "none",
      fontSize: "1.5em",
      textAlign: "center",
      fontFamily: "Open Sans, sans-serif",
      fontWeight: "bold",
      lineHeight: "24px",
      "& div": {
        margin: "10px 0",
        paddingBottom: "20px",
      },
    },
    "@media (max-width: 959px)": {
      "&&": {
        textAlign: "left",
        fontSize: "16px",
      },
      "& div": {
        margin: "0 !important",
        paddingBottom: "0 !important",
      },
    },
  },
  leadByExperts: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  circleOfNuliaUl: {
    display: "flex",
    flexDirection: "column",
    gap: "1.45rem",
  },
  insightsOfNuliaUl: {
    display: "flex",
    flexDirection: "column",
    gap: "0.75rem",
  },
  changeAsAServiceTitle: {
    position: "absolute",
    top: "-8px",
  },
  catalogInfoWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    margin: "0 10px",
    "@media (max-width: 959px)": {
      flexDirection: "column",
      margin: "0 32px",
    },
  },
  catalogInfoBox: {
    textAlign: "left",
    fontSize: "16px",
    flex: 1,
  },
  catalogImageBox: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    "@media (max-width: 959px)": {
      justifyContent: "center",
    },
  },
  catalogImage: {
    flexShrink: 0,
    borderRadius: 4,
  },
  catalogImageMobile: {
    "& img": {
      objectFit: "contain !important",
    },
  },
  catalogInfoParagraph: {
    marginBottom: 16,
    fontSize: 16,
    fontFamily: "Open Sans",
  },
}));

const SolutionPage: FC = () => {
  const classes = useStyles();
  const typo = useTypography();

  const isMobile = useMediaQuery("(max-width:959px)");

  const data = useStaticQuery(graphql`
    query {
      desktopHeroImage: file(
        relativePath: { eq: "product/product-hero-desktop.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 2160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileHeroImage: file(
        relativePath: { eq: "product/product-hero-mobile.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      increaseUsageImage: file(
        relativePath: { eq: "product/increase-usage.png" }
      ) {
        childImageSharp {
          fixed(width: 263) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      earnBadgesImage: file(relativePath: { eq: "product/earn-badges.png" }) {
        childImageSharp {
          fixed(width: 263) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      attainSkillsImage: file(
        relativePath: { eq: "product/attain-skills.png" }
      ) {
        childImageSharp {
          fixed(width: 350) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      attainSkillsImageMobile: file(
        relativePath: { eq: "product/attain-skills.png" }
      ) {
        childImageSharp {
          fixed(width: 280) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktopCreateWorldImage: file(
        relativePath: { eq: "product/create-world-desktop.png" }
      ) {
        childImageSharp {
          fixed(width: 730) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mobileCreateWorldImage: file(
        relativePath: { eq: "product/create-world-mobile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobileCreateWorldTwoImage: file(
        relativePath: { eq: "product/monitor-circle-of-nulia.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      insightsImage: file(relativePath: { eq: "product/insights.png" }) {
        childImageSharp {
          fixed(width: 430) {
            ...GatsbyImageSharpFixed_withWebp
          }
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      outcomesMontageImage: file(
        relativePath: { eq: "product/outcomes-montage.png" }
      ) {
        childImageSharp {
          fixed(width: 540) {
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const desktopVideoUrl = `${IMAGES_PATH}videos/platformVideo1.mp4`;
  const posterImageUrl = `${IMAGES_PATH}videos/platformVideo-keyframe1.jpg`;
  const captionsFile = `${IMAGES_PATH}videos/platformVideo1.vtt`;

  return (
    <Layout>
      <SEO title="Solution" />
      <DiscoverMoreBanner />
      <PurpleAccentHero
        desktopImage={data.desktopHeroImage.childImageSharp.fluid}
        mobileImage={data.mobileHeroImage.childImageSharp.fluid}
        alt="Several people collaborating together in an open office environment"
      >
        <div className={classes.titleHeadingGradientWrapper}>
          <section className={classes.titleHeadingText}>
            <h1 className={typo.h4} style={{ marginBottom: 8 }}>
              Change as a Service
            </h1>
            <h2 className={typo.h6} style={{ marginBottom: 32 }}>
              A data-driven, platform-enabled solution that proves Microsoft 365
              adoption is happening and organizational change is being sustained
            </h2>
            <h2 className={typo.h6} style={{ marginBottom: 32 }}>
              Unlocks the full value of an organization’s Microsoft 365
              investment, including Copilot, while guiding employees to develop
              the digital skills they need to realize their potential
            </h2>
            <InternalButtonLink
              variant="orange"
              href={WORKSHOP}
              style={{ zIndex: 1 }}
            >
              Free Workshop
            </InternalButtonLink>
          </section>
        </div>
      </PurpleAccentHero>
      <section className={classes.videoSection}>
        {isMobile && (
          <h2>
            <div>
              Change as a Service is Powered by Our Nulia Works Platform
            </div>
          </h2>
        )}
        {!isMobile && (
          <h2>Change as a Service is Powered by Our Nulia Works Platform</h2>
        )}
        <ResponsiveVideo
          desktopSrc={desktopVideoUrl}
          posterImage={posterImageUrl}
          captionsFile={captionsFile}
        />
      </section>
      <section className={classes.videoSection}>
        {isMobile && (
          <h2>
            <div>
              A Robust Catalog of Outcomes & Skills to Drive Microsoft 365
              Adoption
            </div>
          </h2>
        )}
        {!isMobile && (
          <h2>
            A Robust Catalog of Outcomes & Skills to Drive Microsoft 365
            Adoption
          </h2>
        )}
        <div className={classes.catalogInfoWrapper}>
          <div className={classes.catalogInfoBox}>
            <h2 className={clsx(typo.h6, classes.catalogInfoParagraph)}>
              Outcomes are the core of how Nulia Works continuously drives and
              sustains change. Nulia Works has an ever-growing catalog of
              Outcomes, making it easy to get started and develop an actionable
              plan to align Outcomes with your specific business goals.
            </h2>
            <h2 className={clsx(typo.h6, classes.catalogInfoParagraph)}>
              Our Outcomes are designed to drive change across an organization
              and are developed across multiple applications, utilizing the true
              value of Copilot & ALL of the Microsoft 365 productivity suite,
              creating business value through technology adoption.
            </h2>
          </div>
          {isMobile && (
            <div className={classes.catalogImageBox}>
              <Img
                fixed={data.outcomesMontageImage.childImageSharp.fixed}
                alt="A image of Outcomes montage"
                className={classes.catalogImageMobile}
              />
            </div>
          )}
          {!isMobile && (
            <div className={classes.catalogImageBox}>
              <Img
                fixed={data.outcomesMontageImage.childImageSharp.fixed}
                alt="A image of Outcomes montage"
                className={classes.catalogImage}
              />
            </div>
          )}
        </div>
      </section>
      <section className={classes.leadByExperts}>
        {isMobile && (
          <h2>
            <div>
              Change as a Service is Led by Our Change Management Experts
            </div>
          </h2>
        )}
        {!isMobile && (
          <h2 className={classes.changeAsAServiceTitle}>
            Change as a Service is Led by Our Change Management Experts
          </h2>
        )}
      </section>
      <div className={classes.createWorldWrapper}>
        <section className={classes.createWorldSection}>
          {isMobile && (
            <div className={classes.createWorldImageWrapper}>
              <Img
                fluid={data.mobileCreateWorldImage.childImageSharp.fluid}
                alt="Several people collaborating together in an open office environment"
                className={classes.createWorldImageMobile}
              />
            </div>
          )}
          <div className={classes.createWorldText}>
            <h2 style={{ fontSize: 28 }}>Our Circle of Nulia Methodology</h2>
            <p>
              The Circle of Nulia methodology provides a revolutionary and
              proven process for driving Microsoft 365 adoption, increasing
              Microsoft 365 usage, and unlocking the full value of Microsoft 365
              investments. 
            </p>
            <p>
              Our Circle of Nulia methodology leverages the Nulia Works platform
              to enable change management experts to execute a continuous
              three-step adoption and change management process, using methods
              and tools in combination to:
            </p>
            <ul className={classes.circleOfNuliaUl}>
              <li>
                Build a personalized adoption solution and implementation plan,
                focused on unlocking organizational business value while driving
                employee skill development
              </li>
              <li>
                Assess solution performance, measuring usage of Microsoft 365
                skills and evaluating progress toward attaining Outcomes and
                sustaining change
              </li>
              <li>
                Champion the change process with experts guiding and engaging
                employees with fun, encouragement, recognition, and rewards all
                along the way
              </li>
            </ul>
          </div>
          {isMobile && (
            <div className={classes.createWorldImageWrapper}>
              <Img
                fluid={data.mobileCreateWorldTwoImage.childImageSharp.fluid}
                alt="A desktop computer screen filled with colorful charts and graphs"
                className={classes.createWorldImageTwoMobile}
              />
            </div>
          )}
          {!isMobile && (
            <div className={classes.createWorldImageWrapper}>
              <Img
                fixed={data.desktopCreateWorldImage.childImageSharp.fixed}
                alt="Several people collaborating together in an open office environment"
                className={classes.createWorldImageDesktop}
              />
            </div>
          )}
        </section>
      </div>

      <ProductCoreValuesSection isMobile={isMobile} />

      <section className={classes.insightsSection}>
        <div>
          {!isMobile && (
            <h2 className={classes.insightsSectionTitle}>
              Change as a Service is Driven by Our Data Insights
            </h2>
          )}
        </div>
        <div className={classes.insightsSectionMainBox}>
          <div>
            {isMobile && (
              <Img
                fluid={data.insightsImage.childImageSharp.fluid}
                alt="A woman at her computer exploring the Insights page of the Nulia Works website"
                style={{
                  flexShrink: 0,
                  boxShadow:
                    "0px 24px 32px rgba(0, 0, 0, 0.08), 0px 12.5px 128px rgba(0, 0, 0, 0.08)",
                }}
              />
            )}
            {isMobile && (
              <h2 className={classes.insightsSectionTitle}>
                <div>Change as a Service is Driven by Our Data Insights</div>
              </h2>
            )}
            <aside>
              Our data and insights drive the adoption process and prove real
              change is being sustained
            </aside>
            <p>
              Nulia Works continuously gathers data on how organizations and
              employees are using Copilot & Microsoft 365. Our data is used to:
            </p>
            <ul className={classes.insightsOfNuliaUl}>
              <li>
                Formulate metrics to evaluate whether new Microsoft 365 skills
                have been developed and are being used
              </li>
              <li>
                Create personalized adoption plans for each employee aligned to
                organizational goals
              </li>
              <li>
                Guide employees with skill and activity recommendations so they
                develop skills and attain Outcomes
              </li>
              <li>
                Provide recognition of accomplishment, making the process of
                change fun and rewarding
              </li>
              <li>
                Ensure change is sustained, continuously assessing progress to
                verify skills are being used and maintained
              </li>
            </ul>
            <ButtonLink variant="orange" href={WORKSHOP}>
              FREE WORKSHOP
            </ButtonLink>
          </div>
          <div>
            {!isMobile && (
              <Img
                fixed={data.insightsImage.childImageSharp.fixed}
                alt="A woman at her computer exploring the Insights page of the Nulia Works website"
                style={{
                  marginLeft: 34,
                  flexShrink: 0,
                  boxShadow:
                    "0px 24px 32px rgba(0, 0, 0, 0.08), 0px 12.5px 128px rgba(0, 0, 0, 0.08)",
                }}
              />
            )}
          </div>
        </div>
      </section>
      <CookiesSnackbar />
    </Layout>
  );
};

export default SolutionPage;
